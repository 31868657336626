import {
  Flex,
  Box,
  Text,
  Button,
  Image,
  Heading,
  useBreakpointValue,
} from "@chakra-ui/react";
import LeQuiz from "../components/LeQuiz";
import anniversary from "../img/anniversary.png";
import Footer from "../components/Footer";
import { BsFacebook } from "react-icons/bs";

export default function Cloture() {
  return (
    <Flex direction="column" w="100%" position="relative">
      <Flex direction="column" justify="center" h="full">
        <Flex
          justify={{ base: "space-between", md: "flex-start" }}
          alignItems="center"
          mb="4"
        >
          <LeQuiz homepage={useBreakpointValue({ base: false, md: true })} />
          <Image
            src={anniversary}
            alt="Sofrecom 10 anniversaire"
            maxW="95"
            display={{ base: "inline-flex", md: "none" }}
          />
        </Flex>
        <Box mb="8" alignSelf="stretch" w="100%">
          <Box>
            <Heading
              color="blue.dark"
              size={useBreakpointValue({ base: "2xl", md: "4xl" })}
              as="h1"
              textAlign="left"
              my="6"
              mb="2"
            >
              Quiz clôturé
            </Heading>
          </Box>
          <Flex direction="column" align="flex-start" my="6">
            <Text textAlign="left" my="4" opacity="0.5">
              Suivez-nous sur notre page Facebook “Sofrecom Tunisie” <br /> pour
              découvrir les gagnants de notre Quiz le{" "}
              <strong>10-11-2021</strong>.
            </Text>
            <Flex align="center" wrap="wrap">
              <a
                href="https://www.facebook.com/sofrecomtn"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  colorScheme="facebook"
                  rightIcon={<BsFacebook />}
                  height="45px"
                  width="200px"
                  mt="2"
                >
                  Sofrecom Tunisie
                </Button>
              </a>
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <Box display={{ base: "block", md: "none" }}>
        <Footer homepage={false} />
      </Box>
    </Flex>
  );
}
