import { Flex, Image, Stack, Box, SimpleGrid } from "@chakra-ui/react";
import anniversary from "../img/anniversary.png";
import student from "../img/student.svg";
import success from "../img/success.svg";
import failure from "../img/failure.svg";
import sofrecom from "../img/sofrecom.png";
import orange from "../img/orange.png";
import wecode from "../img/wecode.png";
import papers from "../img/papiers.png";
import papersMobile from "../img/papiers_mobile.png";

export default function SplitScreen({ status, children }) {
  return (
    <Stack
      h={"100vh"}
      direction={{ base: "column", md: "row" }}
      position="relative"
    >
      {status === "success" && (
        <Image
          src={papers}
          position="absolute"
          right="0"
          w="66vw"
          display={{ base: "none", md: "block" }}
        />
      )}
      <Flex
        flex={0.45}
        display={{ base: "none", md: "flex" }}
        direction="column"
        justify="space-around"
        align="center"
        bgGradient="linear(90deg, #DDDDDD -30%, #ECECEC)"
        // p="12"
        py="4"
        minHeight="100vh"
        h={status === "success" || status === "failure" ? "100vh" : "auto"}
      >
        <Flex justify="center" p="8" h="25%">
          <Image
            width="80%"
            alt={"Sofrecom anniversary"}
            src={anniversary}
            objectFit={"contain"}
          />
        </Flex>
        <Flex justify="center" p="8" h="65%">
          <Image
            objectFit="contain"
            alt={"Sofrecom étudiant"}
            src={
              status === "success"
                ? success
                : status === "failure"
                ? failure
                : student
            }
          />
        </Flex>
        <Flex align="flex-end" justify="space-between" w="100%" px="8" h="20%">
          <Flex justify="flex-start" mr="2">
            <Image
              alt={"Sofrecom"}
              src={sofrecom}
              width={{ base: "80%", md: "45%" }}
              minW="120px"
            />
          </Flex>
          <Flex justify="flex-end">
            <Image
              alt={"Orange group"}
              src={orange}
              width={{ base: "80%", md: "60%" }}
              minW="150px"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        px={{ base: 8, md: 8, xl: 16 }}
        py={{ base: 8, md: 8, xl: 10 }}
        flex={0.55}
        backgroundImage={
          status === "success"
            ? { base: `url(${papersMobile})`, md: "none" }
            : { base: "none" }
        }
        backgroundRepeat={"no-repeat"}
        // backgroundSize="cover"
        backgroundSize="115% 81%"
        backgroundPosition="center center"
      >
        {children}
        {/* <Stack spacing={6} w={"full"} maxW={"lg"}>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}>
            <Text
              as={"span"}
              position={"relative"}
              _after={{
                content: "''",
                width: "full",
                height: useBreakpointValue({ base: "20%", md: "30%" }),
                position: "absolute",
                bottom: 1,
                left: 0,
                bg: "blue.400",
                zIndex: -1,
              }}
            >
              Freelance
            </Text>
            <br />{" "}
            <Text color={"blue.400"} as={"span"}>
              Design Projects
            </Text>{" "}
          </Heading>
          <Text fontSize={{ base: "md", lg: "lg" }} color={"gray.500"}>
            The project board is an exclusive resource for contract work. It's
            perfect for freelancers, agencies, and moonlighters.
          </Text>
          <Stack direction={{ base: "column", md: "row" }} spacing={4}>
            <Button
              rounded={"full"}
              bg={"blue.400"}
              color={"white"}
              _hover={{
                bg: "blue.500",
              }}
            >
              Create Project
            </Button>
            <Button rounded={"full"}>How It Works</Button>
          </Stack>
        </Stack> */}
      </Flex>
    </Stack>
  );
}
