import { useState, useContext, createContext } from "react";
import axiosInstance from "../config/axiosInstance";
import question1 from "../img/question1.svg";
import question2 from "../img/question2.svg";
import question3 from "../img/question3.svg";
import question4 from "../img/question4.svg";
import question5 from "../img/question5.svg";
import question6 from "../img/question6.svg";
import question7 from "../img/question7.svg";
import question8 from "../img/question8.svg";

const QuizContext = createContext();

export const useQuiz = () => {
  return useContext(QuizContext);
};

export function QuizProvider({ children }) {
  const quiz = useQuizProvider();
  return <QuizContext.Provider value={quiz}>{children}</QuizContext.Provider>;
}

export function useQuizProvider() {
  const [questions, setQuestions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [quiz, setQuiz] = useState(1);
  const [answer, setAnswer] = useState({});
  const [isLoadingAnswer, setIsLoadingAnswer] = useState(false);
  const [score, setScore] = useState(null);
  const [youWon, setYouWon] = useState(null);
  const [lastLogin, setLastLogin] = useState("");
  const illustrations = [
    question1,
    question2,
    question3,
    question4,
    question5,
    question6,
    question7,
    question8,
  ];

  const setInitialQuiz = (num) => {
    setQuiz(num);
  };

  //   go to the next question
  const nextQuestion = async () => {
    setIsLoadingAnswer(true);
    try {
      await axiosInstance({
        url: "/answer",
        method: "post",
        data: answer,
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      setIsLoadingAnswer(false);
      setAnswer({});
      setQuiz(quiz + 1);
    } catch (error) {
      setIsLoadingAnswer(false);
      setError(error.response.data);
    }
  };

  // get questions
  const getQuestions = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance({
        url: "/questions",
        method: "get",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      setQuestions(response.data);
      setIsLoading(false);
    } catch (error) {
      setQuestions(null);
      setIsLoading(false);
      setError(error.response.data.msg);
    }
  };

  // answer question
  const answerQuestion = (aswr, data) => {
    setAnswer({ questionId: data._id, aswr });
  };

  const finalQuestion = async (history) => {
    setIsLoadingAnswer(true);
    try {
      const response = await axiosInstance({
        url: "/answer/last",
        method: "post",
        data: answer,
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      setScore(response.data.score);
      setLastLogin(response.data.lastLogin);
      setYouWon(response.data.youWon);
      setIsLoadingAnswer(false);
      if (response.data.youWon) {
        history.push("/félicitations");
      } else {
        history.push("/oops");
      }
    } catch (error) {
      setIsLoadingAnswer(false);
      setError(error.response.data.error);
    }
  };

  return {
    quiz,
    questions,
    isLoading,
    answer,
    error,
    getQuestions,
    illustrations,
    nextQuestion,
    answerQuestion,
    isLoadingAnswer,
    setInitialQuiz,
    finalQuestion,
    score,
    lastLogin,
    youWon,
  };
}
