import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, Spinner, Flex } from "@chakra-ui/react";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import { QuizProvider } from "./providers/QuizProvider";
import { AuthProvider, useAuth } from "./providers/AuthProvider";

const WrappedApp = ({ children }) => {
  const { loadConnectedUser, isLoadingUser } = useAuth();

  let token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      loadConnectedUser();
    }
    // eslint-disable-next-line
  }, []);

  return isLoadingUser && token ? (
    <Flex align="center" justify="center" h="100vh" w="100%" bg="white">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="orange.dark"
        size="xl"
      />
    </Flex>
  ) : (
    children
  );
};

ReactDOM.render(
  <QuizProvider>
    <AuthProvider>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <React.StrictMode>
            <WrappedApp>
              <App />
            </WrappedApp>
          </React.StrictMode>
        </ChakraProvider>
      </BrowserRouter>
    </AuthProvider>
  </QuizProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
