import { useState, useContext, createContext } from "react";
import axiosInstance from "../config/axiosInstance";
import { useQuiz } from "./QuizProvider";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }) {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export function useAuthProvider() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorRegister, setErrorRegister] = useState(null);
  const [errorLogin, setErrorLogin] = useState(null);
  const [sendMailLoading, setSendMailLoading] = useState(false);
  const [msgAfterSendMail, setMsgAfterSendMail] = useState("");
  const [errorSendMail, setErrorSendMail] = useState(null);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState(null);
  const [resetPasswordResult, setResetPasswordResult] = useState(null);
  const [error, setError] = useState(null);

  const { getQuestions, setInitialQuiz } = useQuiz();

  //   REGISTER USER
  const facebookRegister = async (data, history) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance({
        url: "/facebook",
        method: "post",
        data: { accessToken: data.accessToken, userId: data.userID },
      });
      localStorage.setItem("token", response.data.token);
      setUser(response.data.user);
      setToken(response.data.token);
      setIsLoading(false);
      setIsLoadingUser(false);
      setIsLoggedIn(true);
      setInitialQuiz(response.data.user.step);
      getQuestions();
      history.push("/quiz");
    } catch (error) {
      localStorage.removeItem("token");
      setUser(null);
      setToken(null);
      setIsLoggedIn(false);
      setIsLoadingUser(false);
      setIsLoading(false);
      setError(error);
    }
  };

  //   LOAD CONNECTED USER
  const loadConnectedUser = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance({
        url: "/user",
        method: "get",
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
      });
      setUser(response.data);
      setIsLoading(false);
      setIsLoggedIn(true);
      setIsLoadingUser(false);
      setInitialQuiz(response.data.step);
      getQuestions();
    } catch (error) {
      localStorage.removeItem("token");
      setUser(null);
      setIsLoadingUser(false);
      setToken(null);
      setIsLoggedIn(false);
      setIsLoading(false);
      setError(error.response.data.error);
    }
  };

  //   UPDATE CONNECTED USER
  const updateUser = async (data, history) => {
    setIsLoading(true);
    if (data.phone && data.status && user.email) {
      try {
        const response = await axiosInstance({
          url: "/user",
          method: "put",
          data,
          headers: {
            "auth-token": localStorage.getItem("token"),
          },
        });
        setUser(response.data);
        setIsLoading(false);
        history.push("/quiz");
        getQuestions();
      } catch (error) {
        localStorage.removeItem("token");
        setIsLoadingUser(false);
        setToken(null);
        setIsLoggedIn(false);
        setIsLoading(false);
        setError(error);
      }
    } else {
      setIsLoading(false);
      setError("Tout les champs sont obilgatoires");
      setTimeout(() => {
        setError(null);
      }, 4000);
    }
  };

  //   REGISTER WITH EMAIL
  const emailRegister = async (data, history) => {
    setIsLoadingEmail(true);
    try {
      const response = await axiosInstance({
        url: "/user",
        method: "post",
        data,
      });
      localStorage.setItem("token", response.data.token);
      setUser(response.data.user);
      setToken(response.data.token);
      setIsLoadingEmail(false);
      setIsLoadingUser(false);
      setIsLoggedIn(true);
      setErrorRegister(null);
      history.push("/quiz");
      getQuestions();
    } catch (error) {
      localStorage.removeItem("token");
      setUser(null);
      setToken(null);
      setIsLoggedIn(false);
      setIsLoadingUser(false);
      setIsLoadingEmail(false);
      if (error.response.data.fields) {
        setErrorRegister(error.response.data.msg);
      } else {
        setError(error.response.data.msg);
      }
      setTimeout(() => {
        setError(null);
      }, 10000);
    }
  };

  // LOGIN USER WITH EMAIL
  const loginEmail = async (data, history) => {
    setIsLoadingEmail(true);
    try {
      const response = await axiosInstance({
        url: "/user/login",
        method: "post",
        data,
      });
      localStorage.setItem("token", response.data.token);
      setUser(response.data.user);
      setToken(response.data.token);
      setIsLoadingEmail(false);
      setIsLoadingUser(false);
      setIsLoggedIn(true);
      setErrorLogin(null);
      setError(null);
      history.push("/quiz");
      setInitialQuiz(response.data.user.step);
      getQuestions();
    } catch (error) {
      localStorage.removeItem("token");
      setUser(null);
      setToken(null);
      setIsLoggedIn(false);
      setIsLoadingUser(false);
      setIsLoadingEmail(false);
      if (error.response.data.fields) {
        setErrorLogin(error.response.data.msg);
      } else {
        setError(error.response.data.msg);
      }
      //   setError(error);
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  };

  const sendEmailAddress = async (email) => {
    setSendMailLoading(true);
    setErrorSendMail(null);
    setMsgAfterSendMail("");
    try {
      await axiosInstance({
        url: "/password/forgot",
        method: "put",
        data: { email },
      });
      setMsgAfterSendMail(
        "Un email a été envoyé, merci de vérifier votre boite email"
      );
      setSendMailLoading(false);
    } catch (error) {
      console.log(error);
      setErrorSendMail(error.response.data.error);
    }
  };

  const resetPassword = async (password, resetLink) => {
    setResetPasswordLoading(true);
    setResetPasswordError(null);
    setResetPasswordResult(false);
    try {
      const response = await axiosInstance({
        url: "/password/reset",
        method: "put",
        data: { newPwd: password, resetLink },
      });
      setResetPasswordLoading(false);
      setResetPasswordResult(true);
      localStorage.setItem("token", response.data.token);
      loadConnectedUser();
    } catch (error) {
      console.log(error);
      setResetPasswordError(error.response.data.error);
      setResetPasswordResult(false);
      setResetPasswordLoading(false);
    }
  };

  const signout = (history) => {
    localStorage.removeItem("token");
    setUser(null);
    setIsLoggedIn(false);
    setToken(null);
    setError(null);
    setErrorLogin(null);
    setErrorRegister(null);
    history.push("/");
  };

  return {
    user,
    token,
    isLoading,
    isLoadingEmail,
    isLoadingUser,
    isLoggedIn,
    error,
    facebookRegister,
    loadConnectedUser,
    updateUser,
    emailRegister,
    loginEmail,
    errorRegister,
    errorLogin,
    signout,
    sendEmailAddress,
    errorSendMail,
    msgAfterSendMail,
    sendMailLoading,
    resetPassword,
    resetPasswordLoading,
    resetPasswordError,
    setResetPasswordError,
    resetPasswordResult,
  };
}
