import { SimpleGrid, Flex, Image } from "@chakra-ui/react";
import sofrecom from "../img/sofrecom.png";
import orange from "../img/orange.png";
import wecode from "../img/wecode.png";

export default function Footer({ homepage }) {
  return (
    <SimpleGrid
      columns={3}
      spacing={{ base: "5px", "2xl": "40px" }}
      alignItems="end"
      mt={homepage ? "0" : "4"}
      mb={homepage ? "4" : "0"}
      // h="20%"
    >
      <Flex justify="center" align="center">
        <Image
          alt={"Sofrecom"}
          src={sofrecom}
          width={{ base: "80%", md: "70%" }}
          maxWidth={{ base: 100, md: 260 }}
        />
      </Flex>
      <Flex justify="center" align="center">
        <Image
          alt={"WeCode"}
          src={wecode}
          width={{ base: "80%", md: "90%" }}
          maxWidth={{ base: 60, md: 180 }}
        />
      </Flex>
      <Flex justify="center" align="center">
        <Image
          alt={"Orange group"}
          src={orange}
          maxWidth={{ base: 100, sm: 146, md: 260 }}
        />
      </Flex>
    </SimpleGrid>
  );
}
