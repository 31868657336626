import { Stack, Heading, useBreakpointValue } from "@chakra-ui/react";

export default function LeQuiz({ homepage }) {
  return (
    <Stack alignItems={"flex-start"} spacing="0">
      <Heading
        as="h2"
        fontWeight="light"
        size={useBreakpointValue(
          homepage
            ? { base: "md", md: "lg" }
            : { base: "md", sm: "md", md: "lg" }
        )}
        textAlign={homepage ? "inherit" : "left"}
      >
        Sofrecom Tunisie
      </Heading>
      <Heading
        as="h1"
        size={useBreakpointValue(
          homepage
            ? { base: "2xl", md: "3xl" }
            : { base: "lg", sm: "xl", md: "2xl" }
        )}
        textTransform="uppercase"
        color="orange.light"
        letterSpacing={{ base: "2.5px", md: "unset" }}
      >
        le quiz
      </Heading>
    </Stack>
  );
}
