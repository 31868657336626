import { Route, Switch } from "react-router-dom";
import "./App.css";
import SplitScreen from "./layouts/SplitScreen";
import Home from "./pages/Home";
import Connexion from "./pages/Connexion";
import Inscription from "./pages/Inscription";
import Question from "./layouts/Question";
import PrivateRoute from "./components/PrivateRoute";
import Information from "./pages/Information";
import Congrats from "./pages/Congrats";
import Oops from "./pages/Oops";
import PrivateChildren from "./components/PrivateChildren";
import ForgotPassword from "./pages/ForgotPassword";
import NewPassword from "./pages/NewPassword";
import Cloture from "./pages/Cloture";

function App() {
  return (
    <div className="full-height">
      <Switch>
        <Route exact path="/">
          <SplitScreen>
            <Cloture />
          </SplitScreen>
        </Route>
        {/* <Route exact path="/" component={Home} /> */}

        {/* <Route exact path="/connexion">
          <SplitScreen>
            <Connexion />
          </SplitScreen>
        </Route>

        <Route exact path="/inscription">
          <SplitScreen>
            <Inscription />
          </SplitScreen>
        </Route>
        <Route exact path="/mot-de-passe-oublié">
          <SplitScreen>
            <ForgotPassword />
          </SplitScreen>
        </Route>
        <Route exact path="/nouveau-mot-de-passe/:resetLink">
          <SplitScreen>
            <NewPassword />
          </SplitScreen>
        </Route> */}

        {/* <PrivateChildren> */}
        {/* <Route exact path="/informations">
          <SplitScreen>
            <Information />
          </SplitScreen>
        </Route> */}
        {/* </PrivateChildren> */}

        {/* <PrivateChildren> */}
        {/* <Route exact path="/félicitations">
          <SplitScreen status="success">
            <Congrats />
          </SplitScreen>
        </Route> */}
        {/* </PrivateChildren> */}

        {/* <PrivateChildren> */}
        {/* <Route exact path="/oops">
          <SplitScreen status="failure">
            <Oops />
          </SplitScreen>
        </Route> */}
        {/* </PrivateChildren> */}
        {/* <PrivateRoute exact path="/quiz" component={Question} /> */}
      </Switch>
    </div>
  );
}

export default App;
